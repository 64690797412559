import React from "react";
import "../pages/App.css";
import * as styles from "../pages/Home.module.css";
import { navigate } from "gatsby";

function jumpTo(dest, id) {
  navigate(dest);
}
const Checkout = (props) => {
  const hasItems = props.cart > 0;
  const mult = props.cart > 1 ? "orders" : "order";
  return (
    <>
      <button
        className={styles.checkoutButton}
        id="items"
        onClick={() => jumpTo("/cart", `cart`)}
        style={{visibility: hasItems ? 'visible' :  'hidden'}}
      >
        {hasItems ? (
          <>
            {" "}
            Checkout {props.cart} {mult} →
          </>
        ) : (
          <></>
        )}
      </button>
    </>
  );
};
export default Checkout;
